import { gql } from '@apollo/client';
import { idleRateAssetPerformanceSharingInfosFragment } from 'modules/sharing/models/AssetSharingInfos/gql.fragment';
import type { IdleRateAssetSharingInfosFragment } from 'modules/sharing/models/AssetSharingInfos/gql.generated';

type IdleRate = {
  value: number | null;
  assetsCount: number;
  activeAssetsCount: number;
  period: {
    start: string;
    end: string;
  };
};

export type IdleRateReport = {
  current: IdleRate | null;
  previous: IdleRate | null;
} | null;

export type DailyIdleRate = {
  value: number;
  date: string;
  assetsCount: number;
  activeAssetsCount: number;
};

export type DailyIdleGqlResponse = {
  viewer: {
    idleReportDailyIdleRates: DailyIdleRate[];
    idleRateReportLastDays: IdleRateReport;
  };
};

const idleRateFragment = gql`
  fragment IdleRateFragment on IdleRate {
    value
    assetsCount
    activeAssetsCount
    period {
      start
      end
    }
  }
`;

export const dailyIdleRateGqlQuery = gql`
  ${idleRateFragment}

  query getDailyIdleRateReport(
    $assetFilters: ReportAssetFilter!
    $periodScale: PeriodScale!
    $since: String!
    $until: String!
  ) {
    viewer {
      id
      idleReportDailyIdleRates(
        assetFilters: $assetFilters
        since: $since
        until: $until
        periodScale: $periodScale
      ) {
        value
        date
        assetsCount
        activeAssetsCount
      }
      idleRateReportLastDays(
        assetFilters: $assetFilters
        since: $since
        until: $until
      ) {
        current {
          ...IdleRateFragment
        }
        previous {
          ...IdleRateFragment
        }
      }
    }
  }
`;

type Category = {
  id: number;
  name: string;
};

export type IdleRateForCategory = {
  key: string;
  data: {
    value: number;
    assetsCount: number;
    activeAssetsCount: number;
  };
};

export type IdleRateByCategoryGqlResponse = {
  viewer: {
    organization: {
      categories: Category[];
    };
    idleReportIdleRatesByCategory: IdleRateForCategory[];
  };
};

export const idleRatesByCategoryGqlQuery = gql`
  query getIdleRateByCategoryReport(
    $assetFilters: ReportAssetFilter!
    $since: String!
    $until: String!
  ) {
    viewer {
      id
      organization  {
        id
        categories {
          id
          name
        }
      }
      idleReportIdleRatesByCategory(
        assetFilters: $assetFilters
        since: $since
        until: $until
      ) {
        key
        data {
          value
          assetsCount
          activeAssetsCount
        }
      }
    }
  }
`;

export type IdleRateHistoryGqlResponse = {
  viewer: {
    idleReportIdleRateHistory: {
      date: string;
      value: number;
      assetsCount: number;
      activeAssetsCount: number;
    }[];
  };
};

export const idleRateHistoryGqlQuery = gql`
  query idleRateHistory(
    $assetFilters: ReportAssetFilter!
    $periodScale: PeriodScale!
  ) {
    viewer {
      id
      idleReportIdleRateHistory(
        assetFilters: $assetFilters
        periodScale: $periodScale
      ) {
        date
        value
        assetsCount
        activeAssetsCount
      }
    }
  }
`;

export type IdleRateReportOverviewByPeriodResponse = {
  viewer: {
    idleRateReportCurrentWeek: IdleRateReport;
    idleRateReportCurrentMonth: IdleRateReport;
    idleRateReportCurrentYear: IdleRateReport;
  } | null;
};

export const idleRateReportOverviewByPeriodQuery = gql`
  ${idleRateFragment}
  query idleRateReportOverviewByPeriodQuery($assetFilters: ReportAssetFilter!) {
    viewer {
      id
      idleRateReportCurrentWeek(assetFilters: $assetFilters) {
        current {
          ...IdleRateFragment
        }
        previous {
          ...IdleRateFragment
        }
      }
      idleRateReportCurrentMonth(assetFilters: $assetFilters) {
        current {
          ...IdleRateFragment
        }
        previous {
          ...IdleRateFragment
        }
      }
      idleRateReportCurrentYear(assetFilters: $assetFilters) {
        current {
          ...IdleRateFragment
        }
        previous {
          ...IdleRateFragment
        }
      }
    }
  }
`;

export type IdleRateAssetsPerformanceRow = {
  assetInOrganizationId: number;
  assetId: number;
  name: string | null;
  enterprise: string | null;
  categoryId: number | null;
  model: string | null;
  make: string | null;
  serialNumber: string | null;
  source: string | null;
  usageHours: number | null;
  previousIdleRateDelta: number | null;
  currentIdleRate: number | null;
  previousIdleRate: number | null;
  categoryIdleRate: number | null;
  categoryDelta: number | null;
  modelIdleRate: number | null;
  modelDelta: number | null;
  modelAssetsCount: number | null;
  categoryAssetsCount: number | null;
} & IdleRateAssetSharingInfosFragment;

export type IdleRateAssetsPerformanceResponse = {
  viewer: {
    idleRateAssetsPerformance: {
      rows: IdleRateAssetsPerformanceRow[];
      total: number;
      offset: number;
      limit: number;
    };
  };
};

export const idleRateAssetsPerformanceQuery = gql`
  ${idleRateAssetPerformanceSharingInfosFragment}
  query idleRateAssetsPerformanceQuery(
    $assetFilters: ReportAssetFilter!
    $since: String!
    $until: String!
    $limit: Int!
    $offset: Int!
    $sortBy: [IdleRateAssetsPerformanceSort!]!
  ) {
    viewer {
      id
      idleRateAssetsPerformance(
        assetFilters: $assetFilters
        since: $since
        until: $until
        limit: $limit
        offset: $offset
        sortBy: $sortBy
      ) {
        rows {
          assetInOrganizationId
          assetId
          name
          enterprise
          categoryId
          model
          make
          serialNumber
          source
          usageHours
          currentIdleRate
          previousIdleRate
          previousIdleRateDelta
          categoryIdleRate
          categoryDelta
          modelIdleRate
          modelDelta
          modelAssetsCount
          categoryAssetsCount
          ...IdleRateAssetSharingInfos
        }
        total
        offset
        limit
      }
    }
  }
`;

export type IdleRateEnterprisesPerformanceRow = {
  enterprise: string | null;
  usageHours: number | null;
  previousIdleRateDelta: number | null;
  currentIdleRate: number | null;
  previousIdleRate: number | null;
};

export type IdleRateEnterprisesPerformanceResponse = {
  viewer: {
    idleRateEnterprisesPerformance: {
      rows: IdleRateEnterprisesPerformanceRow[];
      total: number;
      offset: number;
      limit: number;
    };
  };
};

export const idleRateEnterprisesPerformanceQuery = gql`
  query idleRateEnterprisesPerformanceQuery(
    $assetFilters: ReportAssetFilter!
    $since: String!
    $until: String!
    $limit: Int!
    $offset: Int!
    $sortBy: [IdleRateEnterprisesPerformanceSort!]!
  ) {
    viewer {
      id
      idleRateEnterprisesPerformance(
        assetFilters: $assetFilters
        since: $since
        until: $until
        limit: $limit
        offset: $offset
        sortBy: $sortBy
      ) {
        rows {
          enterprise
          currentIdleRate
          previousIdleRate
          previousIdleRateDelta
          usageHours
        }
        total
        offset
        limit
      }
    }
  }
`;

export type IdleRateAssetsListItem = {
  assetInOrganizationId: number;
  assetId: number;
  name: string | null;
  make: string | null;
  idleRate: number;
};

export type IdleRateAssetsListResponse = {
  viewer: {
    idleRateAssetList: IdleRateAssetsListItem[];
  };
};

export type AssetListDataSource = 'daily' | 'monthly';

export const idleRateAssetsListQuery = gql`
  query idleRateAssetList(
    $assetFilters: ReportAssetFilter!
    $period: Period!
    $dataSource: AssetListDataSource!
  ) {
    viewer {
      id
      idleRateAssetList(
        assetFilters: $assetFilters
        period: $period
        dataSource: $dataSource
      ) {
        assetInOrganizationId
        assetId
        name
        make
        idleRate
      }
    }
  }
`;
