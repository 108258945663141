import type { AssetFilterGQL } from 'modules/analytics/components/AssetFilter/types';
import { SimpleValueWithInfosCard } from 'modules/analytics/components/Cards/SimpleValueCard/SimpleValueCardWithInfos';
import {
  AssetListDrawer,
  type TrackingProperties,
} from 'modules/analytics/components/common/AssetListDrawer';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  type PeriodScale,
  formatDateWithPeriodScale,
  getPeriodLabel,
} from 'utils/time';
import { AssetsListTable } from '../../../components/AssetsListTable';
import { useAssetListExport } from '../../../lib/exportAssetListHook';

type EvolutionDrawerProps = {
  selectedPeriod: { since: Date; until: Date } | null;
  granularityScale: PeriodScale;
  open: boolean;
  assetFilters: AssetFilterGQL;
  onClose: () => void;
  usedAssetsCount?: number;
  activeAssetsCount?: number;
  averageIdleRate: number | null;
  trackingProps?: TrackingProperties;
};

export const EvolutionDrawer = ({
  activeAssetsCount,
  assetFilters,
  averageIdleRate,
  granularityScale,
  onClose,
  open,
  selectedPeriod,
  usedAssetsCount,
  trackingProps,
}: EvolutionDrawerProps) => {
  const { t } = useTranslation('analytics');
  const { triggerAssetListExport, exportLoading } = useAssetListExport();

  const handleAssetListExport = useCallback(() => {
    if (!selectedPeriod) return;
    triggerAssetListExport(
      assetFilters,
      selectedPeriod.since,
      selectedPeriod.until,
      'daily',
    );
  }, [assetFilters, selectedPeriod, triggerAssetListExport]);

  const mainDrawerContent = () => {
    if (!selectedPeriod) return null;
    return (
      <AssetsListTable
        assetFilters={assetFilters}
        assetListDataSource="daily"
        since={selectedPeriod.since}
        until={selectedPeriod.until}
        onExportClick={handleAssetListExport}
        exportLoading={exportLoading}
      />
    );
  };

  const drawerHeader = () => {
    if (!selectedPeriod) return null;
    const title =
      granularityScale === 'day'
        ? formatDateWithPeriodScale(selectedPeriod.since, granularityScale)
        : getPeriodLabel(selectedPeriod.since, selectedPeriod.until);
    return (
      <SimpleValueWithInfosCard
        title={title}
        infos={t('reports.common.assetsCountOverTotal', {
          count: usedAssetsCount,
          total: activeAssetsCount,
        })}
        unit="%"
        value={averageIdleRate}
      />
    );
  };

  return (
    <AssetListDrawer
      open={open}
      title={t('reports.idleRate.analysis.evolution.assetDrawerTitle')}
      header={drawerHeader()}
      mainContent={mainDrawerContent()}
      onClose={onClose}
      trackingProps={trackingProps}
    />
  );
};
