import colors from 'constants/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

export const Title = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  padding: 0 2px;
  color: ${colors.gray500};
`;

export const Subtitle = styled.div<{
  hasRef?: boolean;
}>`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${colors.gray500};
  padding: 0 2px;
  ${(props) =>
    props.hasRef &&
    `
    &:hover {
      background-color: ${colors.gray200};
      border-radius: 3px;
      cursor: pointer;
    }
  `};
`;
