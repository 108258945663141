import { useQuery } from '@apollo/client';
import type { TFunction } from 'i18next';
import type { AssetFilterGQL } from 'modules/analytics/components/AssetFilter/types';
import { AssetsTable } from 'modules/analytics/components/common/AssetListDrawer/AssetTable';
import { RateCell } from 'modules/analytics/components/common/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { CellProps, Column } from 'react-table';
import {
  type AssetListDataSource,
  type IdleRateAssetsListItem,
  type IdleRateAssetsListResponse,
  idleRateAssetsListQuery,
} from '../gql';

type AssetsListTableProps = {
  assetFilters: AssetFilterGQL;
  assetListDataSource: AssetListDataSource;
  since: Date;
  until: Date;
  onExportClick?: () => void;
  exportLoading?: boolean;
};

const getMetricColumn = (t: TFunction): Column<IdleRateAssetsListItem> => ({
  Header: t('reports.idleRate.table.columns.idleRate'),
  id: 'idleRate',
  Cell: ({ row: { original } }: CellProps<IdleRateAssetsListItem>) => {
    return <RateCell rate={original.idleRate} />;
  },
  headerStyle: { paddingLeft: '8px' },
  style: { paddingLeft: '8px' },
  sortType: (rowA, rowB) => {
    return rowA.original.idleRate - rowB.original.idleRate;
  },
});

export const AssetsListTable = ({
  assetFilters,
  assetListDataSource,
  since,
  until,
  onExportClick,
  exportLoading,
}: AssetsListTableProps) => {
  const { t } = useTranslation('analytics');
  const [rows, setRows] = useState<IdleRateAssetsListItem[]>([]);

  const parseResponse = (data: IdleRateAssetsListResponse) => {
    setRows(data.viewer.idleRateAssetList);
  };

  const { loading } = useQuery<IdleRateAssetsListResponse>(
    idleRateAssetsListQuery,
    {
      fetchPolicy: 'cache-and-network',
      skip: !since || !until,
      onCompleted: parseResponse,
      variables: {
        assetFilters: assetFilters,
        period: {
          since: since,
          until: until,
        },
        dataSource: assetListDataSource,
      },
    },
  );

  const metricColumn = getMetricColumn(t);

  return (
    <AssetsTable
      title={t('reports.common.assetsUsed')}
      since={since}
      until={until}
      metricColumn={metricColumn}
      loading={loading}
      page="idleRate"
      rows={rows}
      exportLoading={exportLoading}
      onExportClick={onExportClick}
    />
  );
};
