import type { GradientColorObject } from 'highcharts';

export const reportLineFillOptions: GradientColorObject = {
  linearGradient: {
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 1,
  },
  stops: [
    [0, 'rgba(148,159,255,1)'],
    [1, 'rgba(148,159,255,0.12)'],
  ],
};
