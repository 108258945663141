import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { EmptyValue, NoData } from './styles.css';

type OrientationProps = { orientation?: 'column' | 'row' };
const Container = styled.div<OrientationProps>`
  display: flex;
  flex-direction: ${(props) => props.orientation || 'column'};
  gap: 4px;
`;

export const NoValue = ({ orientation }: OrientationProps) => {
  const { t } = useTranslation('analytics');

  return (
    <Container orientation={orientation}>
      <EmptyValue>-</EmptyValue>
      <NoData>{t('simpleValue.noData')}</NoData>
    </Container>
  );
};
