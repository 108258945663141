import { useTranslation } from 'react-i18next';
import { getPeriodLabel } from 'utils/time';
import { Container, Subtitle, Title } from './styles.css';

type SelectedPeriodProps = {
  since: Date | null;
  until: Date | null;
  datePickerRef?: React.RefObject<HTMLDivElement>;
};

export const SelectedPeriod = ({
  datePickerRef,
  since,
  until,
}: SelectedPeriodProps) => {
  const { t } = useTranslation('analytics');
  const hasRef = !!datePickerRef?.current;
  const scrollAndOpenDatePicker = () => {
    if (hasRef) {
      datePickerRef.current?.scrollIntoView({ behavior: 'smooth' });
      datePickerRef.current?.click();
    }
  };

  if (!since || !until) {
    return null;
  }

  const selectedPeriodLabel = getPeriodLabel(since, until);
  return (
    <Container>
      <Title>{t('reports.common.selectedPeriod')}</Title>
      <Subtitle
        hasRef={hasRef}
        onClick={() => {
          scrollAndOpenDatePicker();
        }}
      >
        {selectedPeriodLabel}
      </Subtitle>
    </Container>
  );
};
