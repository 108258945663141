import type React from 'react';
import { type RefObject, createContext, useContext, useRef } from 'react';

type DatePickerRefType = RefObject<HTMLDivElement> | undefined;

const DatePickerRefContext = createContext<DatePickerRefType>(undefined);

export function DatePickerRefProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <DatePickerRefContext.Provider value={ref}>
      {children}
    </DatePickerRefContext.Provider>
  );
}

export function useDatePickerRef() {
  return useContext(DatePickerRefContext);
}
