import { useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { type Unit, displayValueAndUnit } from 'utils/unit';
import { CustomTooltip } from '../../common/CustomTooltip/CustomTooltip';
import { NoValue } from './NoValue';
import { NoTrend } from './Trend/NoTrend';
import { TrendDescription } from './Trend/TrendDescription';
import { Container, InnerContent, Value } from './styles.css';

type SimpleValueProps = {
  value: number | null;
  comparativeValue: number | null;
  loading?: boolean;
  tooltip?: JSX.Element | string;
  unit: Unit;
};

const Loader = () => {
  return (
    <Container>
      <ContentLoader
        width="100%"
        height={60}
        speed={2}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        title="Loading"
      >
        <rect x="9" y="6" rx="4" ry="4" width="100" height="20" />
        <rect x="9" y="40" rx="4" ry="4" width="120" height="13" />
      </ContentLoader>
    </Container>
  );
};

export const SimpleValueDescription = ({
  value,
  comparativeValue,
  loading = false,
  tooltip,
  unit,
}: SimpleValueProps) => {
  const { t } = useTranslation('analytics');
  const [randomId] = useState(() => Math.random().toString(36).slice(2));

  if (loading) {
    return <Loader />;
  }

  const hasValue = value !== null;

  return (
    <Container>
      {hasValue ? (
        <InnerContent data-for={`simple-value-tooltip-${randomId}`} data-tip="">
          <Value>{displayValueAndUnit(value, unit, t)}</Value>
          {comparativeValue !== null ? (
            <TrendDescription
              value={value - comparativeValue}
              unit={unit === '%' ? 'pts' : unit}
            />
          ) : (
            <NoTrend />
          )}
          {tooltip && (
            <CustomTooltip
              tooltipId={`simple-value-tooltip-${randomId}`}
              tooltip={tooltip}
            />
          )}
        </InnerContent>
      ) : (
        <NoValue />
      )}
    </Container>
  );
};
