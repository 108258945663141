import { BREAKPOINT_MOBILE_END } from 'constants/breakpoints';
import colors from 'constants/colors';
import {
  LineChart,
  type LineChartData,
} from 'modules/analytics/components/Dataviz/LineChart/LineChart';
import { SimpleValue } from 'modules/analytics/components/Dataviz/SimpleValue/SimpleValue';
import { Card } from 'modules/analytics/components/common/layout/Card';
import type { DailyIdleRate } from 'modules/analytics/reports/idleRate/gql';
import { Button } from 'modules/common-ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import type { PeriodScale } from 'utils/time';

const CardContainer = styled.div`
  flex: 1 1 calc(50% - 16px); /* Two items per row with gap adjustment */
  max-width: calc(50% - 16px);

  @media (max-width: ${BREAKPOINT_MOBILE_END}px) {
    flex: 1 1 100%; /* Full width for smaller screens */
    max-width: 100%;
  }
`;

const AssetsCount = styled.div`
  color: ${colors.gray500};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 2px;
`;

type IdleRateComponentProps = {
  data: LineChartData[];
  average: {
    value: number | null;
    assetsCount: number;
    activeAssetsCount: number;
  };
  previousPeriodAverage: {
    value: number | null;
    assetsCount: number;
    activeAssetsCount: number;
  };
  setSelectedDataPoint: (period: DailyIdleRate) => void;
  setGranularityScale: (scale: PeriodScale) => void;
};

export const IdleRateComponent = ({
  average,
  data,
  previousPeriodAverage,
  setSelectedDataPoint,
  setGranularityScale,
}: IdleRateComponentProps) => {
  const { t } = useTranslation('newHome');
  const navigate = useNavigate();

  return (
    <>
      <CardContainer>
        <Card
          title={t('analytics.kpi.idleRate.title')}
          titleInfo={t('analytics.kpi.idleRate.help')}
          actionElement={
            <>
              <Button
                onClick={() => {
                  setGranularityScale('week');
                  setSelectedDataPoint({
                    activeAssetsCount: average.activeAssetsCount,
                    value: average.value || 0,
                    assetsCount: average.assetsCount,
                    date: new Date().toISOString(),
                  });
                }}
              >
                {t('analytics.kpi.common.viewAssets')}
              </Button>
              <Button onClick={() => navigate('/analytics/idle-rate')}>
                {t('analytics.kpi.common.analyze')}
              </Button>
            </>
          }
          disableImageExport
        >
          <SimpleValue
            value={average.value}
            unit={'%'}
            comparativeValue={previousPeriodAverage.value}
            orientation="row"
          />
          <AssetsCount>
            {t('analytics.kpi.common.assetsCount', {
              assetsCount: average.assetsCount,
            })}
          </AssetsCount>
          <LineChart
            data={data}
            unit={'%'}
            tooltipV2
            periodScale="day"
            displayBars
            onItemClicked={(item: DailyIdleRate) => {
              setGranularityScale('day');
              setSelectedDataPoint({
                ...item,
                value: item.value * 100,
              });
            }}
          />
        </Card>
      </CardContainer>
    </>
  );
};
