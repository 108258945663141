import styled from 'styled-components';

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ChartContainer = styled.div`
  display: flex;
  height: 300px;
  width: 100%;
  flex: 1;
  padding-top: 17px;
`;

export const DescriptionContainer = styled.div`
  margin-top: 20px;
`;
