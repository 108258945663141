import colors from 'constants/colors';
import styled from 'styled-components';

export const GroupButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 32px;
`;

export const CustomButton = styled.button<{
  selected: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  dataFor?: string;
  dataTip?: string;
}>`
  background-color: ${(props) => (props.selected ? colors.blue50 : 'white')};
  border: 1px solid;
  border-color: ${(props) => (props.selected ? colors.blue200 : colors.gray300)};
  color: ${colors.gray850};
  cursor: ${(props) => (props.selected ? 'default' : 'pointer')};
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  z-index: ${(props) => (props.selected ? 1 : 0)};
  margin-right: -1px;
  position: relative;

  :first-of-type {
    border-radius: 5px 0 0 5px;
  }

  :last-of-type {
    border-radius: 0 5px 5px 0;
    margin-right: 0px;
  }

  :first-of-type:last-of-type {
    border-radius: 5px;
  }

  :hover {
    background-color: ${(props) =>
      props.selected ? colors.blue50 : colors.gray100};
  }

  ${(props) => props.disabled && 'cursor: not-allowed;'}

  ${(props) =>
    (props.disabled || props.isLoading) &&
    `
    pointer-events: all !important;
    border-color: ${colors.gray300};
    color: ${colors.gray300};

    :hover {
      background-color: transparent;
    }
  `}

  :active,
  :focus {
    outline: none;
  }
`;

export const TextContainer = styled.div<{ hideText?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  ${(props) => props.hideText && 'color: transparent;'}
`;

export const DotFlashingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const DotFlashing = styled.div`
  display: inline-block;
  position: absolute;
  width: 2px;
  height: 2px;
  border-radius: 5px;
  background-color: ${colors.blue300};
  color: ${colors.blue300};
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  ::before,
  ::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  ::before {
    left: -5px;
    width: 2px;
    height: 2px;
    border-radius: 2px;
    background-color: ${colors.blue300};
    color: ${colors.blue300};
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  ::after {
    left: 5px;
    width: 2px;
    height: 2px;
    border-radius: 2px;
    background-color: ${colors.blue300};
    color: ${colors.blue300};
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: ${colors.blackNeutral};
    }
    50% {
      background-color: ${colors.blue300};
    }
    100% {
      background-color: ${colors.blue200};
    }
  }
`;
