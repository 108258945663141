import colors from 'constants/colors';
import styled from 'styled-components';

export const PeriodTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
`;

export const Period = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${colors.gray500};
`;

export const PeriodContainer = styled.div`
  margin-bottom: 4.5px;
`;
