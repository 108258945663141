import { useQuery } from '@apollo/client';
import type { TFunction } from 'i18next';
import type { AssetFilterGQL } from 'modules/analytics/components/AssetFilter/types';
import { AssetsTable } from 'modules/analytics/components/common/AssetListDrawer/AssetTable';
import { HourlyConsumptionCell } from 'modules/analytics/components/common/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { CellProps, Column } from 'react-table';
import {
  type AssetListDataSource,
  type HourlyConsumptionAssetsListItem,
  type HourlyConsumptionAssetsListResponse,
  hourlyConsumptionAssetsListQuery,
} from '../gql';

type AssetsListTableProps = {
  assetFilters: AssetFilterGQL;
  assetListDataSource: AssetListDataSource;
  since: Date;
  until: Date;
  onExportClick?: () => void;
  exportLoading?: boolean;
};

const getMetricColumn = (
  t: TFunction,
): Column<HourlyConsumptionAssetsListItem> => ({
  Header: t(
    'reports.hourlyConsumption.analysis.table.columns.hourlyConsumption',
  ),
  id: 'hourlyConsumption',
  Cell: ({ row: { original } }: CellProps<HourlyConsumptionAssetsListItem>) => {
    const { averageFuelUsedPerHour } = original;
    return <HourlyConsumptionCell hourlyConsumption={averageFuelUsedPerHour} />;
  },
  headerStyle: { paddingLeft: '8px' },
  style: { paddingLeft: '8px' },
  sortType: (rowA, rowB) => {
    return (
      rowA.original.averageFuelUsedPerHour -
      rowB.original.averageFuelUsedPerHour
    );
  },
});

export const AssetsListTable = ({
  assetFilters,
  assetListDataSource,
  since,
  until,
  exportLoading,
  onExportClick,
}: AssetsListTableProps) => {
  const { t } = useTranslation('analytics');
  const [rows, setRows] = useState<HourlyConsumptionAssetsListItem[]>([]);

  const parseResponse = (data: HourlyConsumptionAssetsListResponse) => {
    setRows(data.viewer.hourlyConsumptionAssetList);
  };

  const { loading } = useQuery<HourlyConsumptionAssetsListResponse>(
    hourlyConsumptionAssetsListQuery,
    {
      fetchPolicy: 'cache-and-network',
      skip: !since || !until,
      onCompleted: parseResponse,
      variables: {
        assetFilters: assetFilters,
        period: {
          since: since,
          until: until,
        },
        dataSource: assetListDataSource,
      },
    },
  );

  const metricColumn = getMetricColumn(t);

  return (
    <AssetsTable
      since={since}
      until={until}
      metricColumn={metricColumn}
      loading={loading}
      page="hourlyConsumption"
      rows={rows}
      exportLoading={exportLoading}
      onExportClick={onExportClick}
    />
  );
};
