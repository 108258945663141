import { useQuery } from '@apollo/client';
import type { AssetFilterGQL } from 'modules/analytics/components/AssetFilter/types';
import {
  type HourlyConsumptionCustomPeriodGqlResponse,
  hourlyConsumptionCustomPeriodGqlQuery,
} from 'modules/analytics/reports/hourlyConsumption/gql';
import { parseChartData } from 'modules/analytics/reports/hourlyConsumption/sections/HourlyConsumptionAnalysis/cards/Evolution';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useGetHourlyConsumptionData = ({
  since,
  until,
  assetFilters,
}: { since: Date; until: Date; assetFilters: AssetFilterGQL }) => {
  const { t } = useTranslation('analytics');

  const { loading, data } = useQuery<HourlyConsumptionCustomPeriodGqlResponse>(
    hourlyConsumptionCustomPeriodGqlQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        assetFilters: assetFilters,
        since,
        until,
        periodScale: 'DAY',
      },
    },
  );

  const parsedData = useMemo(() => {
    return parseChartData(
      { since, until },
      'day',
      data?.viewer?.hourlyConsumptionCustomPeriod,
      data?.viewer?.hourlyConsumptionAverage,
      t,
    );
  }, [data, since, until, t]);

  return {
    hourlyConsumptionData: parsedData,
    loading,
  };
};
