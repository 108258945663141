import { gql } from '@apollo/client';
import { hourlyConsumptionAssetPerformanceSharingInfosFragment } from 'modules/sharing/models/AssetSharingInfos/gql.fragment';
import type { HourlyConsumptionAssetSharingInfosFragment } from 'modules/sharing/models/AssetSharingInfos/gql.generated';

export type HourlyConsumptionHistoryItem = {
  date: string;
  value: number;
  assetsCount: number;
  activeAssetsCount: number;
};

export type HourlyConsumptionHistoryGqlResponse = {
  viewer: {
    hourlyConsumptionHistory: HourlyConsumptionHistoryItem[];
  };
};

export const hourlyConsumptionHistoryGqlQuery = gql`
  query getHourlyConsumptionHistoryReport(
    $assetFilters: ReportAssetFilter!
    $periodScale: PeriodScale!
  ) {
    viewer {
      id
      hourlyConsumptionHistory(
        assetFilters: $assetFilters
        periodScale: $periodScale
      ) {
        date
        value
        assetsCount
        activeAssetsCount
      }
    }
  }
`;

export type HourlyConsumptionAssetsPerformanceRow = {
  assetInOrganizationId: number;
  assetId: number;
  name: string | null;
  enterprise: string | null;
  categoryId: number | null;
  model: string | null;
  make: string | null;
  serialNumber: string | null;
  source: string | null;
  usageHours: number | null;
  fuelUsed: number | null;
  previousHourlyConsumptionDelta: number | null;
  currentHourlyConsumption: number | null;
  previousHourlyConsumption: number | null;
  categoryHourlyConsumption: number | null;
  categoryDelta: number | null;
  modelHourlyConsumption: number | null;
  modelDelta: number | null;
  modelAssetsCount: number | null;
  categoryAssetsCount: number | null;
} & HourlyConsumptionAssetSharingInfosFragment;

export type HourlyConsumptionAssetsPerformanceResponse = {
  viewer: {
    hourlyConsumptionAssetsPerformance: {
      rows: HourlyConsumptionAssetsPerformanceRow[];
      total: number;
      offset: number;
      limit: number;
    };
  };
};

export const hourlyConsumptionAssetsPerformanceQuery = gql`
  ${hourlyConsumptionAssetPerformanceSharingInfosFragment}
  query hourlyConsumptionAssetsPerformanceQuery(
    $assetFilters: ReportAssetFilter!
    $since: String!
    $until: String!
    $limit: Int!
    $offset: Int!
    $sortBy: [HourlyConsumptionAssetsPerformanceSort!]!
  ) {
    viewer {
      id
      hourlyConsumptionAssetsPerformance(
        assetFilters: $assetFilters
        since: $since
        until: $until
        limit: $limit
        offset: $offset
        sortBy: $sortBy
      ) {
        rows {
          assetInOrganizationId
          assetId
          name
          enterprise
          categoryId
          model
          make
          serialNumber
          source
          usageHours
          fuelUsed
          currentHourlyConsumption
          previousHourlyConsumption
          previousHourlyConsumptionDelta
          categoryHourlyConsumption
          categoryDelta
          modelHourlyConsumption
          modelDelta
          modelAssetsCount
          categoryAssetsCount
          ...HourlyConsumptionAssetSharingInfos
        }
        total
        offset
        limit
      }
    }
  }
`;

export type HourlyConsumptionReport = {
  current: {
    value: number | null;
    assetsCount: number;
    activeAssetsCount: number;
    period: {
      start: string;
      end: string;
    };
  } | null;
  previous: {
    value: number | null;
    assetsCount: number;
    activeAssetsCount: number;
    period: {
      start: string;
      end: string;
    };
  } | null;
} | null;

export type HourlyConsumptionReportOverviewByPeriodResponse = {
  viewer: {
    hourlyConsumptionCurrentWeek: HourlyConsumptionReport;
    hourlyConsumptionCurrentMonth: HourlyConsumptionReport;
    hourlyConsumptionCurrentYear: HourlyConsumptionReport;
  } | null;
};

export const hourlyConsumptionReportOverviewByPeriodQuery = gql`
  fragment HourlyConsumptionComparativePeriodFragment on HourlyConsumptionComparativePeriod {
    current {
      value
      assetsCount
      activeAssetsCount
      period {
        start
        end
      }
    }
    previous {
      value
      assetsCount
      activeAssetsCount
      period {
        start
        end
      }
    }
  }

  query hourlyConsumptionReportOverviewByPeriodQuery(
    $assetFilters: ReportAssetFilter!
  ) {
    viewer {
      id
      hourlyConsumptionCurrentWeek(assetFilters: $assetFilters) {
        ...HourlyConsumptionComparativePeriodFragment
      }
      hourlyConsumptionCurrentMonth(assetFilters: $assetFilters) {
        ...HourlyConsumptionComparativePeriodFragment
      }
      hourlyConsumptionCurrentYear(assetFilters: $assetFilters) {
        ...HourlyConsumptionComparativePeriodFragment
      }
    }
  }
`;

export type HourlyConsumptionResult = {
  value: number;
  date: string;
  assetsCount: number;
  activeAssetsCount: number;
};

export type HourlyConsumptionCustomPeriodGqlResponse = {
  viewer: {
    hourlyConsumptionCustomPeriod: HourlyConsumptionResult[];
    hourlyConsumptionAverage: HourlyConsumptionReport;
  };
};

export const hourlyConsumptionCustomPeriodGqlQuery = gql`
  query getHourlyConsumptionCustomPeriod(
    $assetFilters: ReportAssetFilter!
    $periodScale: PeriodScale!
    $since: String!
    $until: String!
  ) {
    viewer {
      id
      hourlyConsumptionCustomPeriod(
        assetFilters: $assetFilters
        since: $since
        until: $until
        periodScale: $periodScale
      ) {
        value
        date
        assetsCount
        activeAssetsCount
      }
      hourlyConsumptionAverage(
        assetFilters: $assetFilters
        since: $since
        until: $until
      ) {
        current {
          value
          assetsCount
          activeAssetsCount
        }
        previous {
          value
          assetsCount
          activeAssetsCount
        }
      }
    }
  }
`;

type Category = {
  id: number;
  name: string;
};

export type HourlyConsumptionForCategory = {
  key: string | null;
  data: {
    value: number;
    assetsCount: number;
    activeAssetsCount: number;
  };
};

export type HourlyConsumptionByCategoryGqlResponse = {
  viewer: {
    organization: {
      categories: Category[];
    };
    hourlyConsumptionByCategory: HourlyConsumptionForCategory[];
  };
};

export const hourlyConsumptionByCategoryGqlQuery = gql`
  query getHourlyConsumptionByCategory(
    $assetFilters: ReportAssetFilter!
    $since: String!
    $until: String!
  ) {
    viewer {
      id
      organization {
        id
        categories {
          id
          name
        }
      }
      hourlyConsumptionByCategory(
        assetFilters: $assetFilters
        since: $since
        until: $until
      ) {
        key
        data {
          value
          assetsCount
          activeAssetsCount
        }
      }
    }
  }
`;

export type HourlyConsumptionEnterprisesPerformanceRow = {
  enterprise: string | null;
  usageHours: number | null;
  previousHourlyConsumptionDelta: number | null;
  currentHourlyConsumption: number | null;
  previousHourlyConsumption: number | null;
};

export type HourlyConsumptionEnterprisesPerformanceResponse = {
  viewer: {
    hourlyConsumptionEnterprisesPerformance: {
      rows: HourlyConsumptionEnterprisesPerformanceRow[];
      total: number;
      offset: number;
      limit: number;
    };
  };
};

export const hourlyConsumptionEnterprisesPerformanceQuery = gql`
  query hourlyConsumptionEnterprisesPerformanceQuery(
    $assetFilters: ReportAssetFilter!
    $since: String!
    $until: String!
    $limit: Int!
    $offset: Int!
    $sortBy: [HourlyConsumptionEnterprisesPerformanceSort!]!
  ) {
    viewer {
      id
      hourlyConsumptionEnterprisesPerformance(
        assetFilters: $assetFilters
        since: $since
        until: $until
        limit: $limit
        offset: $offset
        sortBy: $sortBy
      ) {
        rows {
          enterprise
          currentHourlyConsumption
          previousHourlyConsumption
          previousHourlyConsumptionDelta
          usageHours
        }
        total
        offset
        limit
      }
    }
  }
`;

export type HourlyConsumptionAssetsListItem = {
  assetInOrganizationId: number;
  assetId: number;
  name: string | null;
  make: string | null;
  averageFuelUsedPerHour: number;
};

export type HourlyConsumptionAssetsListResponse = {
  viewer: {
    hourlyConsumptionAssetList: HourlyConsumptionAssetsListItem[];
  };
};

export type AssetListDataSource = 'daily' | 'monthly';

export const hourlyConsumptionAssetsListQuery = gql`
  query HourlyConsumptionAssetList(
    $assetFilters: ReportAssetFilter!
    $period: Period!
    $dataSource: AssetListDataSource!
  ) {
    viewer {
      id
      hourlyConsumptionAssetList(
        assetFilters: $assetFilters
        period: $period
        dataSource: $dataSource
      ) {
        assetInOrganizationId
        assetId
        name
        make
        averageFuelUsedPerHour
      }
    }
  }
`;
