import zipcelx from 'zipcelx';

export const downloadXLSX = (chart: Highcharts.Chart) => {
  const rows = chart.getDataRows(true);
  const xlsxRows = rows.slice(1).map((row) =>
    row.map((column) => ({
      type: (typeof column === 'number' ? 'number' : 'string') as
        | 'number'
        | 'string',
      value: column,
    })),
  );
  // will build and trigger the file download client side
  zipcelx({
    filename: 'chart',
    sheet: {
      data: xlsxRows,
    },
  });
};
