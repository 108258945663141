import { Tooltip } from 'modules/common-ui';
import { Fragment, useEffect, useState } from 'react';
import {
  CustomButton,
  DotFlashing,
  DotFlashingContainer,
  GroupButtonsContainer,
  TextContainer,
} from './styles.css';

export type GroupButtonOptions<T = string> = {
  text: string;
  value: T;
  disabled?: boolean;
  tooltip?: string;
};

type GroupButtonsProps<T = string> = {
  options: GroupButtonOptions<T>[];
  onChange?: (value: T) => void;
  disabled?: boolean;
  loading?: boolean;
  selectedValue?: T;
};

export const GroupButtons = <T,>({
  options,
  onChange,
  disabled = false,
  loading = false,
  selectedValue,
}: GroupButtonsProps<T>) => {
  const [randomTooltipId] = useState(
    Math.floor(Math.random() * 10000).toString(),
  );
  const [localSelected, setLocalSelected] = useState(
    selectedValue ? selectedValue : options.length ? options[0].value : '',
  );

  useEffect(() => {
    if (typeof selectedValue === 'undefined') return;
    setLocalSelected(selectedValue);
  }, [selectedValue]);

  const handleClick = (value: T) => {
    if (loading || localSelected === value) return;
    setLocalSelected(value);
    onChange?.(value);
  };

  if (!options.length) return null;

  return (
    <GroupButtonsContainer>
      {options.map((option, index) => (
        <Fragment key={option.value as string}>
          <CustomButton
            selected={localSelected === option.value}
            onClick={() => handleClick(option.value)}
            isLoading={loading}
            disabled={disabled || option.disabled}
            data-for={`period-scale-${randomTooltipId}-${index}`}
            data-tip=""
          >
            <TextContainer hideText={loading && localSelected === option.value}>
              {option.text}
              {loading && localSelected === option.value && (
                <DotFlashingContainer>
                  <DotFlashing />
                </DotFlashingContainer>
              )}
            </TextContainer>
          </CustomButton>
          {option.tooltip && (
            <Tooltip
              id={`period-scale-${randomTooltipId}-${index}`}
              place="bottom"
            >
              {option.tooltip}
            </Tooltip>
          )}
        </Fragment>
      ))}
    </GroupButtonsContainer>
  );
};
