import type { AssetFilterGQL } from 'modules/analytics/components/AssetFilter/types';
import { trackReportExported } from 'modules/analytics/lib/tracking';
import { apiClient } from 'modules/api-client';
import { useExportInProgressToast } from 'modules/exportCenter/hooks/exportInProgressToast';
import { useTracking } from 'modules/utils';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import type { Store } from 'reducers';

const useExportAssetListAsync = () => {
  const { track } = useTracking();
  const [exportLoading, setExportLoading] = useState(false);
  const { userToken } = useSelector((state: Store) => ({
    userToken: state.user.token || '',
    email: state.user.email || '',
  }));

  const exportAssetList = useCallback(
    (
      assetFilters: AssetFilterGQL,
      since: Date,
      until: Date,
      dataSource: 'daily' | 'monthly',
    ) => {
      const fileType = 'XLSX';
      trackReportExported(track, 'hourlyConsumption', 'assetList', fileType);
      setExportLoading(true);
      return apiClient.analytics
        .requestExportHourlyConsumptionAssetList(userToken, {
          assetFilters,
          since,
          until,
          dataSource,
          fileType,
        })
        .finally(() => {
          setExportLoading(false);
        });
    },
    [track, userToken],
  );

  return { exportAssetList, exportLoading };
};

export const useAssetListExport = () => {
  const { t } = useTranslation('translation');
  const displayExportInProgressToast = useExportInProgressToast();

  const { exportAssetList, exportLoading } = useExportAssetListAsync();

  const onExport = useCallback(
    (
      assetFilters: AssetFilterGQL,
      since: Date,
      until: Date,
      dataSource: 'daily' | 'monthly',
    ) => {
      exportAssetList(assetFilters, since, until, dataSource)
        .then(displayExportInProgressToast)
        .catch(() => {
          toast.error(t('translation:common.exportFailed'), {
            position: 'top-right',
          });
        });
    },
    [exportAssetList, displayExportInProgressToast, t],
  );

  return { triggerAssetListExport: onExport, exportLoading };
};
