import { useQuery } from '@apollo/client';
import type { TFunction } from 'i18next';
import type { AssetFilterGQL } from 'modules/analytics/components/AssetFilter/types';
import { MultiChartCard } from 'modules/analytics/components/Cards/MultiChartCard';
import { DescriptionContainer } from 'modules/analytics/components/Cards/MultiChartCard/styles.css';
import type { LineChartData } from 'modules/analytics/components/Dataviz/LineChart/LineChart';
import { SimpleValueDescription } from 'modules/analytics/components/Dataviz/SimpleValue/SimpleValueDescription';
import type { PeriodScaleOption } from 'modules/analytics/components/common/PeriodScaleButtons/PeriodScaleButtons';
import { SelectedPeriod } from 'modules/analytics/components/common/SelectedPeriod/SelectedPeriod';
import { TooltipContent } from 'modules/analytics/components/common/Tooltip/Tooltip';
import { fillEmptyData } from 'modules/analytics/lib/fillEmptyData';
import moment from 'moment';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  type PeriodScale,
  formatDateShort,
  formatPreviousPeriod,
  getDateShortFormat,
  getEndPeriodDate,
  getUTCEndOfDayDate,
  getUTCStartOfDayDate,
} from 'utils/time';

import { useDatePickerRef } from 'context/analytics/DatePickerRefContext';
import { Bold } from 'modules/common-ui';
import { displayValueAndUnit } from 'utils/unit';
import {
  type HourlyConsumptionCustomPeriodGqlResponse,
  type HourlyConsumptionReport,
  type HourlyConsumptionResult,
  hourlyConsumptionCustomPeriodGqlQuery,
} from '../../../gql';
import { EvolutionDrawer } from './EvolutionDrawer';
import { Period, PeriodContainer, PeriodTitle } from './styles.css';

type HourlyConsumptionAnalysisCardProps = {
  assetFilters: AssetFilterGQL;
  since: Date;
  until: Date;
};

type EvolutionData = {
  data: LineChartData[];
  average: {
    value: number | null;
    assetsCount: number;
    activeAssetsCount: number;
  };
  previousPeriodAverage: {
    value: number | null;
    assetsCount: number;
    activeAssetsCount: number;
  };
};

export const parseChartData = (
  period: { since: Date; until: Date },
  periodScale: PeriodScale,
  hourlyConsumptionsData: HourlyConsumptionResult[] | undefined,
  hourlyConsumptionAverage: HourlyConsumptionReport | undefined,
  t: TFunction<'analytics', undefined>,
): EvolutionData => {
  const res: EvolutionData = {
    data: [],
    average: { value: null, assetsCount: 0, activeAssetsCount: 0 },
    previousPeriodAverage: {
      value: null,
      assetsCount: 0,
      activeAssetsCount: 0,
    },
  };
  if (hourlyConsumptionsData) {
    res.data = fillEmptyData(
      hourlyConsumptionsData.map((item) => ({
        value: item.value,
        date: new Date(+item.date),
        tooltipData: [
          {
            label: t(
              'reports.hourlyConsumption.overview.chart.tooltip.hourlyConsumption',
            ),
            value: `<b>${displayValueAndUnit(item.value, 'L/h', t)}</b>`,
          },
          {
            label: t(
              'reports.hourlyConsumption.overview.chart.tooltip.usedAssets',
            ),
            value: `<b>${item.assetsCount}</b>/${item.activeAssetsCount}`,
          },
        ],
        originalData: item,
      })),
      period,
      periodScale,
    );
  }
  if (hourlyConsumptionAverage) {
    res.average =
      hourlyConsumptionAverage.current === null
        ? { value: null, assetsCount: 0, activeAssetsCount: 0 }
        : hourlyConsumptionAverage.current;
    res.previousPeriodAverage =
      hourlyConsumptionAverage.previous === null
        ? { value: null, assetsCount: 0, activeAssetsCount: 0 }
        : hourlyConsumptionAverage.previous;
  }
  return res;
};

const granularityInDays: Record<string, number> = {
  day: 1,
  week: 7,
  month: 30,
};

const isGranularityRelevant = (
  granularity: string,
  dataSince: Date,
  dataUntil: Date,
): boolean => {
  const diffInDays = Math.round(
    (dataUntil.getTime() - dataSince.getTime()) / (1000 * 3600 * 24),
  );
  return diffInDays > (granularityInDays[granularity] || 0);
};

export const EvolutionCard = ({
  assetFilters,
  since,
  until,
}: HourlyConsumptionAnalysisCardProps) => {
  const { t } = useTranslation('analytics');
  const [selectedItem, setSelectedItem] =
    useState<HourlyConsumptionResult | null>(null);
  const datePickerRef = useDatePickerRef();
  const granularityScaleOptions = useMemo(() => {
    const options: PeriodScaleOption[] = [
      {
        text: t('reports.hourlyConsumption.analysis.evolution.daily'),
        value: 'day',
      },
      {
        text: t('reports.hourlyConsumption.analysis.evolution.weekly'),
        value: 'week',
      },
      {
        text: t('reports.hourlyConsumption.analysis.evolution.monthly'),
        value: 'month',
      },
    ];
    // check if we need to disable any granularity scale
    for (const option of options) {
      if (isGranularityRelevant(option.value, since, until)) {
        continue;
      }
      option.disabled = true;
      option.tooltip = t('periodScale.unrelevantScale');
    }
    return options;
  }, [since, until, t]);

  const [granularityScale, setGranularityScale] = useState(
    granularityScaleOptions[0].value,
  );

  // update granularity scale if it is not relevant with new dates
  useEffect(() => {
    if (isGranularityRelevant(granularityScale, since, until)) return;
    const newGranularityScale = [...granularityScaleOptions]
      .reverse()
      .find((option) => !option.disabled)?.value;
    if (!newGranularityScale) return;
    setGranularityScale(newGranularityScale);
  }, [since, until, granularityScale, granularityScaleOptions]);

  const { loading, data } = useQuery<HourlyConsumptionCustomPeriodGqlResponse>(
    hourlyConsumptionCustomPeriodGqlQuery,
    {
      fetchPolicy: 'cache-and-network',
      skip: !since || !until,
      variables: {
        assetFilters: assetFilters,
        since: getUTCStartOfDayDate(since),
        until: getUTCEndOfDayDate(until),
        periodScale: granularityScale.toUpperCase(),
      },
    },
  );

  const parsedData = useMemo(() => {
    return parseChartData(
      { since, until },
      granularityScale,
      data?.viewer?.hourlyConsumptionCustomPeriod,
      data?.viewer?.hourlyConsumptionAverage,
      t,
    );
  }, [data, since, until, granularityScale, t]);

  const selectedPeriodComponent = (
    <SelectedPeriod since={since} until={until} datePickerRef={datePickerRef} />
  );

  const assetListPeriod = useMemo(() => {
    if (!selectedItem) return null;
    const since = new Date(+selectedItem.date);
    return {
      since,
      until: getEndPeriodDate(since, granularityScale),
    };
  }, [granularityScale, selectedItem]);

  return (
    <Fragment>
      <MultiChartCard
        trackingProps={{
          page: 'hourlyConsumption',
          section: 'evolution',
        }}
        title={t(
          `reports.hourlyConsumption.analysis.evolution.title.${granularityScale}`,
        )}
        data={parsedData.data}
        average={parsedData.average.value}
        loading={loading}
        scaleOptions={granularityScaleOptions}
        onScaleChange={setGranularityScale}
        yAxisTitle={t(
          'reports.hourlyConsumption.analysis.evolution.yAxisTitle',
        )}
        averageAxisTitle={t(
          'reports.hourlyConsumption.analysis.evolution.averageAxisTitle',
        )}
        selectedScale={granularityScale}
        unit="L/h"
        selectedPeriodComponent={selectedPeriodComponent}
        description={
          <DescriptionContainer>
            <PeriodContainer>
              <PeriodTitle>
                {t(
                  'reports.hourlyConsumption.analysis.periodHourlyConsumption',
                )}
              </PeriodTitle>
              <Period>{` ${moment(since).format('ddd Do MMM')} - ${moment(
                until,
              ).format('ddd Do MMM')}`}</Period>
            </PeriodContainer>
            <SimpleValueDescription
              value={parsedData.average.value}
              comparativeValue={parsedData.previousPeriodAverage.value}
              loading={loading}
              unit="L/h"
              tooltip={
                <TooltipContent
                  label={t(
                    'reports.hourlyConsumption.analysis.evolution.hourlyConsumption',
                  )}
                  currentPeriodLabel={`${formatDateShort(
                    moment(since),
                  )} - ${formatDateShort(moment(until))}`}
                  previousPeriodLabel={formatPreviousPeriod(
                    since,
                    until,
                    getDateShortFormat(),
                  )}
                  currentValue={parsedData.average.value}
                  currentValueDetails={
                    parsedData.average.value ? (
                      <>
                        <Bold>{parsedData.average.assetsCount}</Bold>/
                        {parsedData.average.activeAssetsCount}
                      </>
                    ) : undefined
                  }
                  previousValue={parsedData.previousPeriodAverage.value}
                  previousValueDetails={
                    parsedData.previousPeriodAverage ? (
                      <>
                        <Bold>
                          {parsedData.previousPeriodAverage.assetsCount}
                        </Bold>
                        /{parsedData.previousPeriodAverage.activeAssetsCount}
                      </>
                    ) : undefined
                  }
                  unit="L/h"
                  hideDrawerClickAction
                />
              }
            />
          </DescriptionContainer>
        }
        onItemClicked={(data: HourlyConsumptionResult) => {
          setSelectedItem(data);
        }}
        tooltipV2
      />
      <EvolutionDrawer
        activeAssetsCount={selectedItem?.activeAssetsCount}
        usedAssetsCount={selectedItem?.assetsCount}
        selectedPeriod={assetListPeriod}
        granularityScale={granularityScale}
        open={!!selectedItem}
        assetFilters={assetFilters}
        onClose={() => {
          setSelectedItem(null);
        }}
        averageHourlyConsumption={selectedItem?.value ?? null}
        trackingProps={{
          page: 'hourlyConsumption',
          section: 'analysis',
          source: 'evolutionChart',
        }}
      />
    </Fragment>
  );
};
