import type { HighchartsReactRefObject } from 'highcharts-react-official';
import { downloadXLSX } from 'modules/analytics/lib/downloadXLSX';
import {
  type Page,
  type Section,
  trackPeriodicitySelected,
  trackReportExported,
} from 'modules/analytics/lib/tracking';
import { useTracking } from 'modules/utils';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { PeriodScale } from 'utils/time';
import type { Unit } from 'utils/unit';
import {
  LineChart,
  type LineChartProps,
} from '../../Dataviz/LineChart/LineChart';
import {
  PeriodScaleButtons,
  type PeriodScaleOption,
} from '../../common/PeriodScaleButtons/PeriodScaleButtons';
import { Card } from '../../common/layout/Card';
import { reportLineFillOptions } from '../constants';
import { ChartContainer, RowContainer } from './styles.css';

type MultiChartCardProps = LineChartProps & {
  average: number | null;
  averageAxisTitle?: string;
  onScaleChange?: (value: PeriodScale) => void;
  title?: string;
  loadingAverage?: boolean;
  scaleOptions?: PeriodScaleOption[];
  selectedScale?: PeriodScale;
  unit: Unit;
  description?: React.ReactNode;
  selectedPeriodComponent?: React.ReactNode;
  trackingProps: {
    page: Page;
    section: Section;
  };
  onItemClicked?: (originalData: any) => void;
  tooltipV2?: boolean;
};

export const MultiChartCard = ({
  data,
  average,
  averageAxisTitle = '',
  onScaleChange,
  title,
  yAxisTitle,
  loading = false,
  loadingAverage = false,
  scaleOptions = [],
  selectedScale,
  unit,
  description,
  selectedPeriodComponent,
  trackingProps,
  tooltipV2,
  onItemClicked = () => {},
}: MultiChartCardProps) => {
  const { t } = useTranslation('analytics');
  const rowContainerRef = useRef<HTMLDivElement>(null);
  const chartContainerRef = useRef<HTMLDivElement>(null);

  const chartRef = useRef<HighchartsReactRefObject | null>(null);
  const { track } = useTracking();

  const onScaleChangeWithTracking = (scaleValue: PeriodScale) => {
    if (onScaleChange) {
      onScaleChange(scaleValue);
      trackPeriodicitySelected(
        track,
        trackingProps.page,
        trackingProps.section,
        scaleValue,
      );
    }
  };

  const exportXLS = () => {
    const chart = chartRef.current?.chart;
    if (chart) {
      downloadXLSX(chart);
    }
    trackReportExported(
      track,
      trackingProps.page,
      trackingProps.section,
      'xls',
    );
  };
  const extraMenuOptions = [
    {
      label: t('card.menu.exportFormat', { replace: { format: 'XLSX' } }),
      value: 'xls',
      handler: exportXLS,
    },
  ];

  const actionElement = (
    <PeriodScaleButtons
      options={scaleOptions}
      onChange={onScaleChangeWithTracking}
      loading={loading || loadingAverage}
      selectedValue={selectedScale}
    />
  );

  const horizontalLines = [];
  if (average) {
    horizontalLines.push({
      label: averageAxisTitle,
      value: average,
    });
  }

  return (
    <Card
      title={title}
      menuOptions={extraMenuOptions}
      actionElement={actionElement}
      selectedPeriodComponent={selectedPeriodComponent}
      description={description}
      trackingProps={trackingProps}
    >
      <RowContainer ref={rowContainerRef}>
        <ChartContainer ref={chartContainerRef}>
          <LineChart
            data={data}
            yAxisTitle={yAxisTitle}
            loading={loading}
            fordwardRef={chartRef}
            horizontalLines={horizontalLines}
            periodScale={selectedScale}
            unit={unit}
            onItemClicked={onItemClicked}
            tooltipV2={tooltipV2}
            lineFillOptions={reportLineFillOptions}
          />
        </ChartContainer>
      </RowContainer>
    </Card>
  );
};
